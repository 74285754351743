import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

const HowtoPage = () => (
  <div id="indexPage">
    <Header/>
    <div className="content-pad">
      <h2>Standard Packages</h2>
      <p>
	FISH MED NET هو مشروع يدعم تنويع النشاط الرئيسي للصيادين. ويهدف إلى تمكين الصيادين وتعاونيات الصيد من
استكشاف مسارات مختلفة من أجل زيادة الخدمات بهدف تحسين سبل عيشهم والاقتصاد المحلي والبيئة المحيطة بهم. يتمثل أحد هذه
الأنشطة في تعزيز استخدام مرافقها للخدمات المجتمعية ، حيث يمكن إقامة علاقات رسمية بين الموضوعات الخاصة والعامة.<br/><br/>
على وجه التحديد ، طورت FISH MED NET 4 أنواع مختلفة من الاتفاقيات الرسمية ، والمعروفة باسم الحزم القياسية ، والتي
ستسمح بإطلاق عملية رسمية للمقارنة بين الصيادين والمواضيع العامة والخاصة.
      </p>
	<p>
	فيما يلي روابط الأنواع الأربعة المختلفة للحزم القياسية. يمكن استخدام هذه المستندات بحرية كقوالب وتعديلها بحرية. نأمل أن توفر
هذه الوثائق المساعدة اللازمة لبدء تعاون ناجح ومثمر بين جميع المستخدمين. يكون استخدام هذه الوثائق على عاتق المستخدمين
النهائيين فقط ولا يمكن تحميل مشروع ENI CBC MED والشركاء والمؤسسات مسؤولية أي تناقضات و / أو إجراءات تم
تطويرها من أي من الاتفاقيات الرسمية أو غير الرسمية المبرمة والمنفذة. بواسطة المستخدمين النهائيين.
	</p>
	<p>
	على وجه التحديد ، طورت FISH MED NET 4 أنواع مختلفة من الاتفاقيات الرسمية ، والمعروفة باسم الحزم القياسية ، والتي
ستسمح بإطلاق عملية رسمية للمقارنة بين الصيادين والكيانات العامة والخاصة.<br/><br/>
فيما يلي روابط الأنواع الأربعة المختلفة للحزم القياسية. يجب استخدام هذه المستندات بحرية كقوالب ويمكن تعديلها وتعديلها بحرية.
نأمل أن توفر هذه الوثائق المساعدة اللازمة لبدء تعاون ناجح ومثمر بين جميع المستخدمين. يكون استخدام هذه الوثائق على عاتق
المستخدمين النهائيين فقط ولا يمكن تحميل مشروع ENI CBC MED والشركاء والمؤسسات مسؤولية أي تناقضات و / أو
إجراءات تم تطويرها من أي من الاتفاقيات الرسمية أو غير الرسمية المبرمة والمنفذة. بواسطة المستخدمين النهائيين.
	</p>
<p> <a href="../../standard-packages-ar.zip">Download zip file</a> </p>
      <h2>Explore between our last proposals</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default HowtoPage
